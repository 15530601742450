<template>
  <div class="grid">
    <loading
      :active="isLoadingModel"
      :can-cancel="true"
      color="#274461"
      :is-full-page="fullPage"
    ></loading>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
    <div class="col-12 sticky">
      <div id="custom_card" class="card">
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
            >
              <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                {{ $t('dept') }}
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div
              class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
              v-if="user_type=='Admin'" >
              <Button
                iconPos="right"
                :label="$t('add')"
                icon="pi pi-plus"
                v-tooltip.bottom="$t('add')"
                @click="add_dept"
                size="small"
                style="
                  box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                  border-color: #00b0b0 !important;
                  background-color: #00b0b0 !important;
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">

      <div class="card" id="custom_card">
        <Toast />
        

        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="field">
                <span class="p-float-label">
                  <InputText
                    format="text"
                    v-model="search_cat"
                    @input="get_list(1)"
                  >
                  </InputText>
                  <label>{{ $t('search_dept') }}</label>
                </span>
              </div>&nbsp;&nbsp;&nbsp;
              <div class="field ">
                <i class="pi pi-circle-fill" style="font-size: 1rem; color: #e30000!important; cursor: pointer;" ></i>
                <span  style="font-weight: 600; margin-left: 2px; margin-right: 2px; color: #6b719b">
                    {{ $t('open_ticket') }}
                </span>&nbsp;
                    <i class="pi pi-circle-fill" style="font-size: 1rem; color: #0000e3!important; cursor: pointer;"></i>&nbsp;<span style="font-weight: 600; margin-left: 2px;margin-right: 2px; color: #6b719b">{{ $t('InProgress_Ticket') }}</span>&nbsp;
                    <i class="pi pi-circle-fill" style="font-size: 1rem; color: #009700!important; cursor: pointer;"></i>&nbsp;<span style="font-weight: 600; margin-left: 2px;margin-right: 2px; color: #6b719b">{{$t('close_ticket')}}</span>&nbsp;
                    <i class="pi pi-circle-fill" style="font-size: 1rem; color: #808080 !important; cursor: pointer;"></i>&nbsp;<span style="font-weight: 600; margin-left: 2px;margin-right: 2px; color: #6b719b"> {{ $t('total_ticket') }}</span>&nbsp;
                </div>


          </template>
          <template v-slot:end>
            <div class="d-flex justify-content-end" style="text-align: right" >
            <downloadexcel
              :header="$t('list_of_department')" 
              :name="$t('department_list')" 
              :fetch="fetchData" 
              :fields="jsonFields"
            >
              <Button 
                icon="pi pi-file-excel"  
                class="btn_green"
                v-tooltip.bottom="$t('excel')"
              />
      </downloadexcel>
      <div style=" font-weight: bold ; font-size: 12px; margin-top: 1%;">
              <span>{{ $t('total_dept') }} : </span>{{ dataviewValue.length }}
          </div>
    </div>
          </template>
        </Toolbar>

       
        <!-- :rows="9" -->
        
        <DataView
          :value="dataviewValue"
          :layout="layout"
          :paginator="true"
           :rowsPerPageOptions="PerPageOptions"
          :rows="rowsPerPage"
          :sortOrder="sortOrder"
          :sortField="sortField"
          
        >
          <template #grid="slotProps">
            <div class="grid grid-nogutter">
              <div
                class="col-12 md:col-4"              
              >
                <div
                  style="height: 130px;width: 300px;"
                  class="card m-3 border-1 surface-border zoom"
                  id="custom_card_left"
                  @mouseover="showButtons(index)"
                  @mouseout="hideButtons(index)"
                  :style="{ 'border-left': '5px solid '+ slotProps.data.color + ' !important' }"
                  
                >
                  <div class="flex align-items-center justify-content-between">
                    <div class="flex align-items-center">
                      <p
                        style="
                          color: #6b719b;
                          font-size: 1rem;
                          font-weight: 600;
                          cursor: pointer;
                        "
                        @click="view_categories(slotProps.data)"
                      >
                        {{ slotProps.data.name }}
                      </p>
                    </div>
                    <div class="col-12 md:col-2">
              <Badge
                v-if="slotProps.data.status == 'Active' || slotProps.data.status == 'active'"
                class="btn_green"
                :value="slotProps.data.status"
                severity="success"
              ></Badge>
              <Badge
                v-else-if="slotProps.data.status == 'InActive' || slotProps.data.status == 'In Active'"
                class="btn_red"
                :value="slotProps.data.status"
                severity="danger"
              ></Badge>
            </div>
                  </div>
                
          
                
                  
                  <div class="flex align-items-center justify-content-between" style="margin-top: 5px;">
                    <div class="flex align-items-center">
                      
                      <p
                        style="
                          color: #6b719b;
                          font-size: 0.875rem;
                          font-weight: 300;
                        "
                      >
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:red; cursor: pointer;"  v-tooltip="$t('open_ticket')" @click="go('/tickets',1,slotProps.data._id)"></i>&nbsp;
                      {{ slotProps.data.open_ticket?slotProps.data.open_ticket:'0' }} &nbsp;
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:blue; cursor: pointer;" v-tooltip="$t('InProgress_Ticket')" @click="go('/tickets',2,slotProps.data._id)" ></i>&nbsp;
                      {{ slotProps.data.inprogress_ticket?slotProps.data.inprogress_ticket:'0' }} &nbsp;
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:green; cursor: pointer;" v-tooltip="$t('close_ticket')" @click="go('/tickets',3,slotProps.data._id)"></i>&nbsp;
                      {{ slotProps.data.closed_ticket?slotProps.data.closed_ticket:'0' }} &nbsp;
                      <i class="pi pi-circle-fill" style="font-size: 0.7rem;color:grey; cursor: pointer;" v-tooltip="$t('total_ticket')" @click="go('/tickets',null,slotProps.data._id)"></i>&nbsp;
                      {{ slotProps.data.allticket?slotProps.data.allticket:'0' }} 
                      </p>
                    </div>
                  </div>

                  <div class="flex justify-content-end">
                    <Button
                    v-if="user_type=='Admin'"
                
                      class="p-button-rounded p-button-success p-button-text"
                      @click="edit_dept(slotProps.data)"
                      icon="pi pi-pencil"
                      size="samll"
                    ></Button>
                   
                    <Button
                    v-if="user_type=='Admin'"
                  
                      class="p-button-rounded p-button-danger p-button-text"
                      icon="pi pi-trash"
                      size="samll"
                      @click="delete_toggle(slotProps.data)"
                    ></Button>

                    <OverlayPanel ref="del" >
                            <div class="flex flex-column gap-3 w-25rem">
                              <h5>{{ $t('delete_department') }} </h5>

                              <div class="col-12">
                                <div class="p-fluid formgrid grid">
                                  <div class="field col-12 md:col-12">
                                    <span class="p-float-label">
                                      <Textarea
                                          format="text"
                                          v-model="delete_remark"
                                        >
                                      </Textarea>
                                      <label>{{$t('remark')}}</label>
                                  </span>
                                  </div>

                                  <div class="field col-12 md:col-4" style="margin-left: 31%;">
                                    <Button :label="$t('delete')" @click="delete_product"  class="p-button-primary btn_light_blue"/>
                                  </div>
                                  <div class="field col-12 md:col-4">
                                    <Button :label="$t('cancel')" @click="Cancel_delete"  class="p-button-danger btn_red"/>
                                  </div>

                                </div>
                              </div>

                            </div>
                      </OverlayPanel>
                    <!-- ------------ -->
                  </div>
                
                </div>
              </div>
            </div>
          </template>
        </DataView>

        <!-- <div class="per-page-and-pagination">
          <div class="per-page-dropdown">
            <label for="perPage">per page : </label>
            <Dropdown @change="window_up()" :options="perPageOptions" v-model="rowsPerPage" />
          </div>
        </div> -->


        
        
      </div>
    </div>
    <!-- <Dialog
        v-model:visible="productDialog"
        :breakpoints="{ '960px': '75vw' }"
        :style="{ width: '45vw' }"
        :header="$t('edit_ward')"
        :modal="true"
        position="top"
      >
        <div class="col-12">
          <div class="p-fluid formgrid grid" style="margin-top: 2%">
            <div class="field col-12 md:col-6" style="margin-top: 2%">
        <span class="p-float-label">
        
          <InputText id="ward_number" type="text" v-model="dataviewValue.ward_no"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57" maxlength="10" required />
              <label for="ward_number">{{ $t('ward_number') }} </label>
        </span>
      </div>
            <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                    v-model="dataviewValue.name_mr"
                  />
                  <label for="ticket_title">{{ $t('ward_name_mr') }}</label>
                </span>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                    v-model="dataviewValue.name_en"
                  />
                  <label for="ticket_title">{{ $t('ward_name_en') }}</label>
                </span>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                    <Dropdown 
                    id="status" 
                    v-model="status_selected" 
                    :options="status_dropdownItems" 
                    optionLabel="name" 
                    filter
                    :disabled="!product._id"
                    :class="{ 'p-invalid': submitted && !status_dropdownItem }">
                    </Dropdown>
                  <label for="ticket_title">{{$t('status')}}</label>
                </span>
              </div>
          </div>
        </div>
  
        <template #footer>
          <Button
            :label="$t('save')"
            @click="save_status"
            icon="pi pi-check"
            class="p-button-success"
          />
        </template>
      </Dialog> -->

    <Dialog
          v-model:visible="add_cat_model"
		      :breakpoints="{'960px': '75vw'}"
          :style="{ width: '35vw' }"
          
           :header="isEditMode ? $t('edit_dept') : $t('add_dept')"
          :modal="true"
		      position="top"
        >
        <div class="col-12">
          <div class="p-fluid formgrid grid" style="margin-top: 2%;">
            
             <div class="field col-12 md:col-12" style="margin-top: 3%;">
              <span class="p-float-label">
                <InputText id="ticket_title" type="text" v-model="name_mr" />
                <label for="name" class=" mb-2">{{ $t('dept_name_mr') }}</label>
              </span>
            </div>
            <br>
            <div class="field col-12 md:col-12" style="margin-top: 3%;">
              <span class="p-float-label">
                <InputText id="ticket_title" 
                 
                type="text" v-model="cat_title" />
                <label for="name"  class=" mb-2">{{ $t('dept_name_en') }}</label>
              </span>
            </div>
            <br>
            
           
           
          
           <div class="field col-12 md:col-12" style="margin-top: 3%;">
            <span class="p-float-label">
                    <Dropdown  
                    id="status" 
                    v-model="status_selected" 
                    :options="status_dropdownItems" 
                    
                    
                    optionLabel="name" 
                    filter
                   
                    :class="{ 'p-invalid': submitted && !status_dropdownItem }">
                    </Dropdown>
                    <!-- {{ status_selected }}
                    {{ status_dropdownItems }} -->
                  <label for="ticket_title">{{$t('status')}}</label>
                </span>
           </div>
          
           <div class="field col-12 md:col-12" style="margin-top: 3%;">
             
             <span class="p-float-label">
              <InputText id="sequence_number" type="number" v-model="sequence_number" />
              <label for="sequence_number" >{{ $t('sequence_number') }}</label>
             </span>
            </div>
            
            <div class="field col-12 md:col-12" style="margin-top: 3%;">
             
           <span class="p-float-label">
            <InputText id="code" type="number" v-model="code" />
            <label for="code" >{{ $t('code') }}</label>
           </span>
            </div>
            
          
            <div class="field col-12 md:col-12" style="margin-top: 3%;">
              <label for="cp-hex" class="font-bold block mb-2"> {{ $t('color') }} </label>
              <ColorPicker v-model="colorHEX" inputId="cp-hex" format="hex" class="mb-3" />
            </div>

            <div class="field col-12 md:col-12" style="margin-top: 3%;">
        <label for="total_users" class="font-bold block mb-2">{{ $t('total_users') }}</label>
        <p id="total_users" class="text-lg font-medium">{{ totalUsers }}</p>
      </div>
          </div>
        </div>

        <template #footer>
		      <Button :label="$t('save')" @click="save_dept" icon="pi pi-check" class="p-button-success"/>
          <Button 
          :label="$t('cancel')" 
          @click="add_cat_model = false" 
          icon="pi pi-times" 
          class="p-button-danger" 
          
        />

		    </template>
    </Dialog>
  </div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
import downloadexcel from "vue-json-excel3";
export default {
  data() {
    return {
      rowsPerPage:10,
      productDialog: false,
      PerPageOptions:[10, 50, 100],
      isEditMode:false,
      totalRecords:0,
      status_selected:"",
      name_mr:"",
      sequence_number:"",
      code:"",
      temp_product_id:"",
      delete_remark:"",
      status_dropdownItem: null,
      search_cat:"",
      status_dropdownItems: [
                { name: "Active", value: "Active" },
                { name: "InActive", value: "InActive" },
                
            ],
      closed_tickets: 0,
      cat_title:"",
      colorHEX:"6b719b",
      isLoadingModel: false,
      fullPage: false,
      dataviewValue: [],
      layout: "grid",
      sortKey: null,
      sortOrder: null,
      sortField: null,
      user_type: "",
      client_id: "",
      showButton: [],
      add_cat_model:false,
      id:"",

      client_dropdown:[],
      client_selected:"",
      clientid:"",
      jsonFields:{
        "Sr No": "sr_no",
        "Name" : "name",
          "Total Ticket" : "allticket",
          "Open Ticket" : "open_ticket",
          "In Progress Ticket" : "inprogress_ticket",
          "Close Ticket" : "closed_ticket",
      }
    };
  },
  productService: null,
  computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    },
  },
  watch:{
    currentLocale(){
      this.get_list(this.currentLocale);
      if (this.currentLocale == 'en') {
        this.jsonFields={
          "Sr No":"sr_no",
          "Name" : "name",
          "Total Ticket" : "allticket",
          "Open Ticket" : "open_ticket",
          "In Progress Ticket" : "inprogress_ticket",
          "Close Ticket" : "closed_ticket",
        };
      } else {
        this.jsonFields={
          "अनु. क्र":"sr_no",
         "नाव" : "name",
          "एकूण तिकीट": "allticket",
          "नवीन तक्रार":"open_ticket",
          "प्रगतीपथावर असलेले तिकीट" : "inprogress_ticket",
          "एकूण बंद तक्रार" : "closed_ticket"
        }
      }
     
      
    },
    // async rowsPerPage() 
    // {
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
  } ,
  created() {
   
      if (this.$i18n.locale == 'en') {
        this.jsonFields={
          "Sr No":"sr_no",
          "Name" : "name",
          "Total Ticket" : "allticket",
          "Open Ticket" : "open_ticket",
          "In Progress Ticket" : "inprogress_ticket",
          "Close Ticket" : "closed_ticket",
        };
      } else {
        this.jsonFields={
          "अनु. क्र":"sr_no",
         "नाव" : "name",
          "एकूण तिकीट": "allticket",
          "नवीन तक्रार":"open_ticket",
          "प्रगतीपथावर असलेले तिकीट" : "inprogress_ticket",
          "एकूण बंद तक्रार" : "closed_ticket"
        }
      }
    
  },
  async mounted() {
    this.user_type = localStorage.getItem("user_type");
    this.client_id = localStorage.getItem("client_id");
    await this.get_list();
    await this.getClientMaster();
  },
  computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    }
   
  },
  methods: {
    fetchData() {
      var temp_data = [];
      
      for (let i = 0; i < this.dataviewValue.length; i++) {
        var item = this.dataviewValue[i];
        var sr_no = i + 1 ;
        //console.log("sr_no:", sr_no, "i:", i, ); // Debugging
        temp_data.push({
          sr_no: sr_no,
          name : item.name,
          allticket : item.allticket,
          open_ticket : item.open_ticket,
          inprogress_ticket : item.inprogress_ticket,
          closed_ticket : item.closed_ticket,
        });
      }
      return temp_data;
    },
    window_up()
    {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async view_categories(item)
    { 
      await localStorage.setItem('categoryDetails','');
      await localStorage.setItem('categoryDetails',JSON.stringify(item));
     // this.$router.push({ name: "categoriesdetails" });
      this.$router.push({ name: "tickets" });
    },
    async confirmDeleteProduct(product) 
    {
      this.$confirm.require({
                target: event.currentTarget,
                message: this.$t("dept_delete_msg"),
                icon: 'pi pi-exclamation-triangle',
                accept: () => {


                    var payload=
                    {
                      "client_id":"65a4f82f6177e69880ece5d6",
                      "user_id":localStorage.getItem("id"),
                      "category_id":product._id,
                      lang:this.$i18n.locale

                    }
        
                    this.isLoadingModel = true;
                    var promise = apis.deleteCategory(payload);
                    promise.then((response) => {
                        this.isLoadingModel = false;
                        if (response.data.status==true) 
                        {
                            //this.$swal({title:responseapi.data.message , confirmButtonText: this.$t('ok')});
                            this.$toast.add({
                      severity: "success", // success, info, warn, error
                      summary: this.$t('success'),
                      detail: response.data.message,
                      life: 3000, // Toast disappears after 3 seconds
                  });
                             this.get_list();
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            
                        }
                        else
                        {
                            this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
                        }
                    });

                },
                reject: () => {
                    this.$toast.add({ severity: 'error', summary: 'Cancel', detail: 'You have Cancelled', life: 3000 });
                }
            });
        
    },
    go(path, ticketType, category) {
        
        localStorage.setItem("mis_status_group",ticketType);
        localStorage.setItem("category",category); 
        this.$router.push(path);
      },


    delete_toggle(data) 
    { 
      this.temp_product_id=data._id;
      this.$refs.del.toggle(event);
    },

    delete_product(){
      if (this.delete_remark==null || this.delete_remark=="" || this.delete_remark==undefined) 
        {
          this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail: this.$t('remark_empty_error'),
              life: 3000,
          });
          return false;
        }

      else
      {
        var payload=
        {
          "client_id":"65a4f82f6177e69880ece5d6",
          "user_id":localStorage.getItem("id"),
          "category_id":this.temp_product_id,
          "remark":this.delete_remark,
          lang:this.$i18n.locale
        }

        this.isLoadingModel = true;
        var promise = apis.deleteCategory(payload);
        promise.then((response) => {
            this.isLoadingModel = false;
            if (response.data.status==true) 
            {
               // this.$swal({title:responseapi.data.message , confirmButtonText: this.$t('ok')});
               //this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });

               this.$toast.add({
                      severity: "success", // success, info, warn, error
                      summary: this.$t('success'),
                      detail: response.data.message,
                      life: 3000, // Toast disappears after 3 seconds
                  });

                  this.get_list();
                  this.temp_product_id="";
                  this.delete_remark="";
                window.scrollTo({ top: 0, behavior: 'smooth' });
                
            }
            else
            {
                this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
            }
        });
      }
    },

    Cancel_delete(){
      this.temp_product_id="";
      this.delete_remark="";
      this.$refs.del.hide();
    },
    async save_dept()
    {
      
     
      var name_regex = /^\s*$/;

      if(!this.name_mr){
        {
              this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail: this.$t('department_invalid_marathi'),
              life: 3000,
            });
            return false;
          } 

      }

      if (!/^[\u0900-\u097Fa-zA-Z0-9\s]+$/.test(this.name_mr)) {
        
          this.$toast.add({
            severity: "error",
            summary: this.$t('error'),
            detail: this.$t('dept_mrname_invalid'), 
            life: 3000,
          });
          return false;
        }

      if (this.cat_title=="" || !this.colorHEX) 
      {
        this.$toast.add({
          severity: "error",
          summary: this.$t('error'),
          detail: this.$t('department_empty_error'),
          life: 3000,
        });
        return false;
      }

      if (this.cat_title) 
      {   
          if (name_regex.test(this.cat_title)) 
          {
              this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail: this.$t('department_invalid_error'),
              life: 3000,
            });
            return false;
          }                
      }

      if (!/^[a-zA-Z0-9\s]+$/.test(this.cat_title)) {
        
          this.$toast.add({
            severity: "error",
            summary: this.$t('error'),
            detail: this.$t('dept_enname_invalid'), 
            life: 3000,
          });
          return false;
        }
     

      if(!this.status_selected){
        {
              this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail: this.$t('department_invalid_status'),
              life: 3000,
            });
            return false;
          } 
      }
      if(!this.sequence_number){
        {
              this.$toast.add({
              severity: "error",
              summary: this.$t('error'),
              detail: this.$t('sequence_invalid_msg'),
              life: 3000,
            });
            return false;
          } 
      }

      var color = '#'+this.colorHEX;
      let details=
      {
          
          "client_id":"65a4f82f6177e69880ece5d6",
          "name":this.name_mr,
          "name_en":this.cat_title,
          "code":this.code, 
            "sequence_number":this.sequence_number ,
            "code":this.code,
          
          "color":color,
          "status": this.status_selected.name,
          "user_id":localStorage.getItem("id"),
          lang:this.$i18n.locale
        
		}
      if (this.id) 
      {
        details['id']=this.id;
        
      } 
      
     
      this.isLoadingModel=true;
				var promise = apis.categoryAddEdit(details);
					promise.then((response) => {
            this.isLoadingModel=false;
					//	this.$swal({title:responseapi.data.message , confirmButtonText: this.$t('ok')});

          this.$toast.add({
                      severity: "success", // success, info, warn, error
                      summary: this.$t('success'),
                      detail: response.data.message,
                      life: 3000, // Toast disappears after 3 seconds
                  });

          
            this.add_cat_model=false;
            this.get_list();
        	
					});
    },
    async add_dept()
    { 
      this.isEditMode = false; 
      this.id="";
      this.cat_title ="";
    
      this. name_mr ="";
      this. status_selected="";
      this.colorHEX  ="6b719b";
      this.add_cat_model=true;
      this.sequence_number="";
      this.code="";
    },
    async edit_dept(data)
    { 
      console.log("edit data",data);
      this.isEditMode = true; 
      this.id=data._id;
      
      this.cat_title  = data.name_en;
      this.name_mr = data.name_mr;
      this.status_selected = data.status;
      this.sequence_number = data.sequence_number;
      this.code = data.code;
      
      var color       = data.color;
      this.colorHEX   = color ? color.slice(1) : '6b719b';
      // this.product = { ...product };

      this.data = { ...data };
        if (this.data.status) 
        {
            this.status_selected={ name: this.data.status, value:  this.data.status };
        }
        // if (this.status) 
        // {
        //     this.status_selected={ name: this.status, value:  this.status };
        // }

      this.add_cat_model=true;
    },
    
    async getClientMaster() {
      var data = {
          limit: 10000,
          page_no: 1,
          count: false,
          user_id: localStorage.getItem("id"),
      };
     
      var promise = apis.getclientist(data);
      promise.then((response) => {
       this.client_dropdown = response.data.data;
      });
    },
 
    async get_list() {
      this.clientid = "";
      this.clientid = this.client_selected._id;
     
      if (this.clientid=='' || this.clientid==undefined) 
      {
        this.clientid=localStorage.getItem("client_id");
      }

      var data = {
        // client_id: "65a4f82f6177e69880ece5d6",
        client_id: this.clientid,
         search:this.search_cat,
         status: this.status, 


      
          
       
        lang:this.$i18n.locale,
        user_id:localStorage.getItem('id')
      
      };
      this.isLoadingModel = true;
      var promise = apis.categoryListmaster(data);
      promise.then((response) => {
        this.isLoadingModel = false;
        // this.dataviewValue = [];
       this.dataviewValue = response.data.data;
       console.log("editdata" ,this.dataviewValue);
       if(this.dataviewValue.length>100)
       {
        this.PerPageOptions.push(this.dataviewValue.length)
       }

       console.log("data",this.dataviewValue);
       
      });
    },
    showButtons(index) {
      //this.$set(this.showButton, index, true);
      this.showButton[index] = true;
    },
    hideButtons(index) {
      //this.$set(this.showButton, index, false);
      this.showButton[index] = false;
    },
  },
  components: { downloadexcel, },
};
</script>
<style scoped >
@import "../assets/demo/badges.scss";
#custom_card {
  border-top: 3px solid #6b719b;
}
#custom_card_left {
 
  box-shadow:rgba(0, 0, 0, 0.1) 0px 8px 12px 0px !important;
}
.zoom {
  transform-origin: 0% 0% 1% 1%;
  transition: transform 1s, filter 1s ease-in-out;
}
/* The Transformation */
.zoom:hover {
  transform: scale(1.1);
}

.border-info {
    border-left: 5px solid  #0dcaf0 !important;
}

.per-page-and-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.per-page-dropdown {
  margin-right: 20px;
}
</style>
